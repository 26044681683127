import FlexBox from '@/atomic-components/FlexBox';
import { LencoTypography } from '@/atomic-components/LencoTypography';
import { TCity } from '@/models/location';
import { Box, Divider, useTheme } from '@mui/material';
import City from './City';
import { getColor } from '@/utils/theme/colors';
import OtherCities from './OtherCities';

type TopCitiesProps = {
	topCities?: TCity[];
	otherCities?:TCity[];
	onCityClicked: (city: TCity) => void;
	displayTopCities: () => void;
	displayOtherCities: () => void;
	showOtherCitiesButton: boolean;
	citiesListViewState:any
};

const TopCities = (props: TopCitiesProps) => {
	const theme = useTheme();
	return (
		<Box>
			<FlexBox
				mt={'28px'}
				justifyContent='space-between'
				alignItems={'center'}
				fullWidth
			>
				{/* need to change this is later */}
				<Divider sx={{width:'26%' , background:theme?.colors?.lencoPrimaryN2}}/>
				<LencoTypography variant='H11A_Medium' sx={{color:getColor(' ')}}>
					Or select your city
				</LencoTypography>
				{/* need to change this is later */}
				<Divider sx={{width:'26%' , background:theme?.colors?.lencoPrimaryN2}}/>
			</FlexBox>
			<FlexBox
				direction='row'
				justifyContent='space-evenly'
				alignItems='center'
				wrap={'wrap'}
				sx={{ overflow: 'auto' }}

			>
				{props?.topCities?.map((city) => (
					<City key={city.name} city={city} onClick={props.onCityClicked} />
				))}
			</FlexBox>
			{props?.citiesListViewState && props.otherCities && <OtherCities
				displayTopCities={props?.displayTopCities}
				otherCities={props?.otherCities}
				onCityClicked={props?.onCityClicked}
			/>}
		</Box>
	);
};

export default TopCities;
