import FlexBox from '@/atomic-components/FlexBox';
import { LencoTypography } from '@/atomic-components/LencoTypography';
import { TCity } from '@/models/location';
import { Box, Divider } from '@mui/material';
import City from './City';
import OtherCities from './OtherCities';

type TopCitiesProps = {
	topCities?: TCity[];
	otherCities?: TCity[];
	displayTopCities: () => void;
	onCityClicked: (city: TCity) => void;
	displayOtherCities: () => void;
	showOtherCitiesButton: boolean;
	citiesListViewState:any
};

const TopCities = (props: TopCitiesProps) => {
	return (
		<Box>
			<FlexBox
				mt={'28px'}
				justifyContent='space-between'
				alignItems={'center'}
				fullWidth
			>
				<Divider />
				<LencoTypography variant='H11A_Medium'>
					Or select your city
				</LencoTypography>
				<Divider />
			</FlexBox>
			<FlexBox
				direction='row'
				justifyContent='space-evenly'
				alignItems='center'
				wrap={'wrap'}
				sx={{ overflow: 'auto' }}
			>
				{props?.topCities?.map((city) => (
					<City key={city?.name} city={city} onClick={props?.onCityClicked} />
				))}
			</FlexBox>
			{props?.citiesListViewState && props.otherCities && <OtherCities
				displayTopCities={props?.displayTopCities}
				otherCities={props?.otherCities}
				onCityClicked={props?.onCityClicked}
			/>}
		</Box>
	);
};

export default TopCities;
