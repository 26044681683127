import FlexBox from '@/atomic-components/FlexBox';
import { LencoImage } from '@/atomic-components/Image';
import PageWrapper from '@/components/unlmtd/page-wrapper';
import SubscriptionCard from '@/components/unlmtd/subscription/subscription-card';
import useSubscription from '@/hooks/unlmtd/useSubscription';
import { getOS } from '@/utils/getOS';
import { getStyle } from '@/utils/theme/fonts';
import SubscriptionBack from '@/assets/unlmtd/subscription-back.svg';
import { Box, Button, Typography, useTheme } from '@mui/material';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

const subscriptionImageStates = {
	'kyc-pending': {
		image:
			'https://assets.furlenco.com/s3-furlenco-images/unlmtd-evolve/subscription_states/web/complete_kyc2.png',
		href: 'https://furlenco.sng.link/Aabqe/julj/2h4w',
	},
	TO_BE_FULFILLED: {
		image:
			'https://assets.furlenco.com/s3-furlenco-images/unlmtd-evolve/subscription_states/web/track_order2.png',
		href: 'https://furlenco.sng.link/Aabqe/xi5c/xrp2',
	},
	CREATED: {
		image:
			'https://assets.furlenco.com/s3-furlenco-images/unlmtd-evolve/subscription_states/web/track_order2.png',
		href: 'https://furlenco.sng.link/Aabqe/xi5c/xrp2',
	},
	FULFILLMENT_IN_PROGRESS: {
		image:
			'https://assets.furlenco.com/s3-furlenco-images/unlmtd-evolve/subscription_states/web/track_order2.png',
		href: 'https://furlenco.sng.link/Aabqe/xi5c/xrp2',
	},
	ACTIVATED: {
		image:
			'https://assets.furlenco.com/s3-furlenco-images/unlmtd-evolve/subscription_states/web/manage_subscription2.png',
		href: 'https://furlenco.sng.link/Aabqe/auz8/2q23',
	},
	CANCELLATION_REQUESTED: {
		image:
			'https://assets.furlenco.com/s3-furlenco-images/unlmtd-evolve/subscription_states/web/manage_subscription2.png',
		href: 'https://furlenco.sng.link/Aabqe/auz8/2q23',
	},
	RENEWAL_OVERDUE: {
		image:
			'https://assets.furlenco.com/s3-furlenco-images/unlmtd-evolve/subscription_states/web/manage_subscription2.png',
		href: 'https://furlenco.sng.link/Aabqe/auz8/2q23',
	},
};

function Subscriptions() {
	const router = useRouter();
	const theme = useTheme();
	const [platform, setPlatform] = useState('');
	useEffect(() => {
		setPlatform(getOS());
	}, []);

	const { subscription } = useSubscription();

	if (!subscription?.subscriptionPlans?.length) {
		return (
			<Box sx={{ padding: '0 24px' }}>
				<FlexBox
					sx={{
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						minHeight: 'calc(100vh)',
					}}
				>
					<Typography
						sx={{
							...getStyle('H9A_Medium'),
							color: theme?.colors?.lencoSecondaryN5,
							pb: '20px',
						}}
					>
						Seems like you dont have any subscription at this moment
					</Typography>
					<Link
						href='/unlmtd'
						style={{
							...getStyle('H14-Medium'),
							border: `1px solid ${theme?.colors?.lencoPrimaryP1}`,
							padding: '18px',
							borderRadius: '30px',
							color: theme?.colors?.lencoSecondaryN1,
						}}
					>
						Start Shopping
					</Link>
				</FlexBox>
			</Box>
		);
	}

	return (
		<Box sx={{ minHeight: '100vh', width: '100vw' }}>
			{subscription?.subscriptionPlans
				?.filter((plan: any) => {
					return plan?.subscriptionPlanState != 'CANCELLED';
				})
				?.map((subscriptionPlan: any, index: number) => (
					<Box key={index}>
						<Box
							sx={{
								position: 'fixed',
								bottom: '84px',
								left: '0',
								zIndex: '9999',
							}}
						>
							<Link href='/'>
								<Box
									sx={{
										background:
											'linear-gradient(90deg, #222038 0%, #642C5E 23.77%, #702E65 65.42%)',
										borderRadius: '0 30px 30px 0 ',
										...getStyle('P_Medium'),
										color: 'white',
										textDecoration: 'underline',
										padding: '17px 28px 17px 46px',
									}}
								>
									<Typography sx={{ ...getStyle('P_Medium') }}>
										<span style={{ marginRight: '9px' }}>
											<SubscriptionBack />
										</span>
										Back to home
									</Typography>
								</Box>
							</Link>
						</Box>
						<>
							{subscriptionPlan?.status?.value == 'kyc-pending' ? (
								<Link
									href={
										subscriptionImageStates[
											subscriptionPlan?.status
												?.value as keyof typeof subscriptionImageStates
										].href
									}
								>
									<LencoImage
										lazy={false}
										aspectRatio={2}
										url={
											subscriptionImageStates[
												subscriptionPlan?.status
													?.value as keyof typeof subscriptionImageStates
											].image
										}
										imageCss={{
											width: '100vw',
											height: 'auto',
										}}
									/>
								</Link>
							) : (
								<Link
									href={
										subscriptionImageStates[
											subscriptionPlan?.subscriptionPlanState as keyof typeof subscriptionImageStates
										]?.href
									}
								>
									<LencoImage
										lazy={false}
										aspectRatio={2}
										url={
											subscriptionImageStates[
												subscriptionPlan?.subscriptionPlanState as keyof typeof subscriptionImageStates
											].image
										}
										imageCss={{
											width: '100vw',
											height: 'auto',
										}}
									/>
								</Link>
							)}
						</>
					</Box>
				))}
		</Box>
	);
}

// Subscriptions.avoidBaseLayout = true

// Subscriptions.getLayout = (page:any) => {
//     return page;
// }

export default Subscriptions;
