import FlexBox from '@/atomic-components/FlexBox';
import LencoButton from '@/atomic-components/LencoButton';
import { LencoTypography } from '@/atomic-components/LencoTypography';
import { TCity } from '@/models/location';

import { getColor } from '@/utils/theme/colors';
import { getStyle } from '@/utils/theme/fonts';
import { Box, Divider, useTheme } from '@mui/material';

type OtherCitiesProps = {
	otherCities?: TCity[];
	onCityClicked: (city: TCity) => void;
	displayTopCities: () => void;
};

const OtherCities = (props: OtherCitiesProps) => {
	const theme = useTheme();
	return (
		<Box width='100%' minHeight='58vh'>
			<FlexBox
				mt={'28px'}
				justifyContent='start'
				alignItems={'center'}
				fullWidth
			>
				<Divider />
				<LencoTypography
					variant='H11A_Medium'
					sx={{ color: theme?.colors?.lencoPrimaryN3 }}
				>
					Other Cities
				</LencoTypography>
				<Divider />
			</FlexBox>
			{props.otherCities &&<FlexBox
				direction='column'
				justifyContent='space-between'
				alignItems='start'
				wrap={'wrap'}
				sx={{ overflow: 'auto' }}
				pt='24px'
			>
				{props.otherCities?.map((city) => (
					<Box
						width='48%'
						key={city.name}
						onClick={() => props.onCityClicked(city)}
						sx={{
							...getStyle('P_Medium'),
							color: getColor('Gray 5'),
							textTransform: 'capitalize',
							borderBottom: `1px solid ${getColor('Gray -5')}`,
							p: '16px 0px',
							cursor: 'pointer',
						}}
					>
						<p>{city.name}</p>
					</Box>
				))}
			</FlexBox>}
			{/* <FlexBox justifyContent='center'
				// variant='text'
				sx={{
					marginTop: '20px',
					textTransform: 'capitalize',
					cursor:'pointer',
					// textDecoration: 'underline',
					
					color: theme?.colors?.lencoSecondaryN1,
						
				}}
				onClick={props.displayTopCities}
			>
				Go Back
			</FlexBox> */}
		</Box>
	);
};

export default OtherCities;
