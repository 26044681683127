import { LencoImage } from '@/atomic-components/Image';
import { TCity } from '@/models/location';
import { getColor } from '@/utils/theme/colors';
import { Box } from '@mui/material';
import { LencoTypography } from '@/atomic-components/LencoTypography';

type TCityIconProps = {
	city: TCity;
	onClick: (cityId: TCity) => void;
};

const City = (props: TCityIconProps) => {
	function onClick() {
		props.onClick(props?.city);
	}

	return (
		<Box className='cursor-pointer' width='80px' m='16px' onClick={onClick}>
			<LencoImage aspectRatio={80 / 64} url={props.city.icon_url}></LencoImage>
			<LencoTypography
				color={getColor('Gray -2')}
				variant='P_Medium'
				mt='10px'
				alignItems='center'
				textAlign='center'
			>
				{props?.city?.name}
			</LencoTypography>
		</Box>
	);
};

export default City;
