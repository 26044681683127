import FlexBox from '@/atomic-components/FlexBox';
import { getColor } from '@/utils/theme/colors';
import {
	TextField,
	InputAdornment,
	IconButton,
	Box,
	Typography,
} from '@mui/material';
import { LencoTypography } from '@/atomic-components/LencoTypography';
import RightArrowIcon from '@/assets/RightArrowIcon';
import DropdownIcon from '@/assets/dropDownIcon.svg';

import { useState } from 'react';
import { getStyle } from '@/utils/theme/fonts';
import { emitMoeEvent } from '@/utils/dataLayer';

type TPincodeInputProps = {
	value: string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	checkPincode: () => void;
	currentlySelectedPincode?: number;
	pincodeError?: string;
};

const PincodeInput = (props: TPincodeInputProps) => {
	function onFormSubmit(e: any) {
		e.preventDefault();
		props.checkPincode();
	}

	const [showInputbox, setShowInputbox] = useState(false);

	function onKeyUp(e: any) {
		if (e.keyCode === 13) {
			props.checkPincode();
		}
	}

	return (
		<FlexBox direction='column' fullWidth alignItems={'center'}>
			<FlexBox
				justifyContent='center'
				alignItems='center'
				gap='8px'
				sx={{ cursor: 'pointer', marginTop: '1.5rem' }}
				onClick={() => {setShowInputbox((prev) => !prev);
					if(!showInputbox){
						emitMoeEvent('Pincode CTA Clicked')
					}
				}}
			>
				<Typography
					sx={{
						...getStyle('Small_Medium'),
						color: getColor('Minty 0'),
						textDecoration: 'underline',
					}}
				>
					Enter Your pincode
				</Typography>
				<DropdownIcon
					style={{
						transform: showInputbox ? 'rotate(180deg)' : 'rotate(0deg)',
						transition: 'transform 0.5s',
					}}
				/>
			</FlexBox>
			{showInputbox && (
				<form
					onSubmit={onFormSubmit}
					style={{
						width: '100%',
					}}
				>
					<TextField
						value={props?.value}
						autoFocus={true}
						type='mobile'
						inputProps={{
							inputMode: 'numeric',
							pattern: '[0-9]*',
							minLength: 5,
							maxLength: 6,
						}}
						onChange={props?.onChange}
						placeholder='Enter your pincode'
						error={!!props?.pincodeError}
						helperText={props?.pincodeError}
						onKeyUp={onKeyUp}
						InputProps={{
							type: 'tel',
							endAdornment: (
								<InputAdornment position='end'>
									<IconButton
										onClick={props.checkPincode}
										sx={{ height: '36px', width: '36px' }}
									>
										<Box>
											<RightArrowIcon color={getColor('Minty 0')} />
										</Box>
									</IconButton>
								</InputAdornment>
							),
						}}
						sx={{
							marginTop: '10px',
							width: '100%',
							'& .MuiInputBase-root': {
								borderRadius: '30px',
							},
							'& .MuiInputBase-input': {
								padding: '20px 32px',
							},
						}}
					/>
				</form>
			)}
			{props.currentlySelectedPincode && (
				<FlexBox mt={'12px'}>
					<LencoTypography
						mr='4px'
						variant='Small_Medium'
						color={getColor('Gray -2')}
					>
						Currently selected pincode :{' '}
					</LencoTypography>
					<LencoTypography variant='Small_SemiBold'>
						{props?.currentlySelectedPincode}
					</LencoTypography>
				</FlexBox>
			)}
		</FlexBox>
	);
};

export default PincodeInput;
