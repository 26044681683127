import FlexBox from '@/atomic-components/FlexBox';
import { LencoImage } from '@/atomic-components/Image';
import { Box, Typography } from '@mui/material';
import SelectedLocationDisplay from '../../SelectedLocationDisplay';
import { useRouter } from 'next/router';
import SearchBar from '@/components/SearchBar/SearchBar';
import CartIcon from '@/components/Icons/CartIcon';
import WishlistIcon from '@/components/Icons/WishlistIcon';
import BuyPopover from './BuyPopover';
import { CatalogueService } from '@/services/catalogue';
import { useEffect, useState } from 'react';
import { Categories, Category } from '@/services/catalogue/models/categories';
import RentPopover from './RentPopover';
import AccountPopover from './AccountPopover';
import { getColor } from '@/utils/theme/colors';
import { LencoTypography } from '@/atomic-components/LencoTypography';
import { useCart } from '@/hooks/useCart';
import UnlmtdIcon from '@/assets/unlmtdIcon.svg';
import UnlmtdIconHover from '@/assets/unlmtdIconHover.svg';

import Link from 'next/link';
import { getStyle } from '@/utils/theme/fonts';
import Hoverable from '@/atomic-components/Hoverable';
import { useLogin } from '@/hooks/useLogin';
import { emitMoeEvent } from '@/utils/dataLayer';
import { useUser } from '@/hooks/useUser';

type TGlobalHeaderProps = {};

const GlobalHeader = (props: TGlobalHeaderProps) => {
    const router = useRouter();
    const [buyCategories, setBuyCategories] = useState<Categories>();
    const [rentCategories, setRentCategories] = useState<Categories>();
    const [animateCartIcon, setAnimateCartIcon] = useState<boolean>(false);
    const { totalCartItems } = useCart();
    const { isLoggedIn, loginActions } = useLogin();
    const { ghostUser, user } = useUser();
    function getCategoriesForVerticals() {
        CatalogueService.getCategories().then((res) => {
            const data = res.data?.data;

            if (data) {
                const { buy, rent } = data;

                buy && setBuyCategories(buy);
                rent && setRentCategories(rent);
            }
        });
    }

    function navigateToCart() {
        emitMoeEvent('Cart Clicked', {
            items_count: totalCartItems,
        });
        router.push('/cart');
    }

    function navigateTOUnlmtd(){
        window.location.href='/unlmtd'
    }

    function navigateToWishlist() {
        router.push('/my/wishlist');
    }

    function wishlistIconClicked() {
        if (isLoggedIn) {
            navigateToWishlist();
        } else {
            loginActions.showLoginFlow(navigateToWishlist);
        }
    }

    function navigateToHome() {
        router.push('/');
    }

    useEffect(() => {
        if (typeof totalCartItems == 'number') {
            setAnimateCartIcon(true);
            setTimeout(() => setAnimateCartIcon(false), 300);
        }
    }, [totalCartItems]);

    useEffect(() => {
        getCategoriesForVerticals();
        // userActions.getProfileMenu();
    }, []);

    return (
        <FlexBox
            p='18px 40px'
            fullWidth
            sx={{
                zIndex: 10,
                position: 'fixed',
                width: '100%',
                left: '0px',
                top: '0px',
                boxShadow: '0 4px 12px 0 rgba(0,0,0,.05)',
                background: 'linear-gradient(180deg, #F3F3F3 0%, #FFFFFF 99.38%);',
            }}
            alignItems='center'
            justifyContent='center'
        >
            <FlexBox
                fullWidth
                id='header-content'
                justifyContent={'space-between'}
                alignItems={'center'}
            >
                <FlexBox fullWidth alignItems={'center'} justifyContent='flex-start'>
                    <Box
                        className='cursor-pointer'
                        sx={{ height: '20px', width: '188px' }}
                        mr='27px'
                        onClick={navigateToHome}
                    >
                        <LencoImage
                            aspectRatio={188 / 20}
                            url='https://assets.furlenco.com/s3-furlenco-images/yoda/furlenco-logo.png'
                        />
                    </Box>
                    <SelectedLocationDisplay />
                    {Array.isArray(buyCategories?.categories) && (
                        <Box ml='24px'>
                            <BuyPopover
                                categories={buyCategories?.categories as Category[]}
                            />
                        </Box>
                    )}
                    {Array.isArray(rentCategories?.categories) && (
                        <Box ml='24px'>
                            <RentPopover
                                categories={rentCategories?.categories as Category[]}
                            />
                        </Box>
                    )}
                    <Box sx={{ ml: '24px' }} onClick={navigateTOUnlmtd}>

                            {/* <FlexBox>
                            <UnlmtdIcon/>
                            <Typography sx={{...getStyle('H14_Medium'),color:getColor('Gray -2'),ml:'6px'}}> UNLMTD </Typography>
                        </FlexBox> */}
                            <Hoverable
                                icons={{
                                    before: UnlmtdIcon,
                                    after: UnlmtdIconHover,
                                    clicked: UnlmtdIcon,
                                }}
                            />

                    </Box>
                </FlexBox>
                <FlexBox fullWidth justifyContent={'flex-end'} alignItems='center'>
                    <Box mr='32px'>
                        <SearchBar />
                    </Box>
                    {/* <LoginTriggerButton isLoggedIn={isLoggedIn} /> */}
                    <Box mr='32px'>{( ghostUser || user ) && <AccountPopover />}</Box>
                    <Box mr='32px'>
                        <WishlistIcon onClick={wishlistIconClicked} />
                    </Box>
                    <Box className={animateCartIcon ? 'shake-cart-icon' : ''}>
                        <CartIcon id='header-cart-icon' onClick={navigateToCart}>
                            <Box
                                visibility={totalCartItems > 0 ? 'visible' : 'hidden'}
                                sx={{
                                    color: getColor(''),
                                    position: 'absolute',
                                    top: -8,
                                    left: 12,
                                    background: getColor('Minty 0'),
                                    borderRadius: '100%',
                                    p: '4px',
                                }}
                            >
                                <LencoTypography
                                    width={'12px'}
                                    height={'12px'}
                                    variant='Tiny_Regular'
                                    textAlign={'center'}
                                >
                                    {totalCartItems}
                                </LencoTypography>
                            </Box>
                        </CartIcon>
                    </Box>
                </FlexBox>
            </FlexBox>
        </FlexBox>
    );
};

export default GlobalHeader;

